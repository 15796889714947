import React, { useCallback } from "react";
import { TimetableData, otherLang, useModels, TimetableCell } from "./Models";
import SessionCard from "./SessionCard";
import { useTableStyle, tableWidth } from "./Table";
// import TitledPage, { pagePaddings } from "./TitledPage";
import { useLang, resolve } from "helpers/i18n";
import { makeStyles, Box } from "@material-ui/core";
import { navigate } from "gatsby";
import { DetailDialog } from "./DetailDialog";
import { Headline } from "helpers/text";

// ----------------------------------------------------------------
//     レイアウト/トップレベル
// ----------------------------------------------------------------

const useRootStyle = makeStyles(({ palette }) => ({
  root: {
    // カードをタップした時なども含め、デフォルトではとにかくリンクに下線を表示しない。
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },

  container: {
    width: "100%",
  },

  dayAnchors: {
    listStyle: "none",
    textAlign: "center",
    color: palette.background.default,
    // fontSize: ${props => props.theme.fonts.midium};
    paddingBottom: "1.2rem",
    "& li": {
      display: "inline",
      padding: "16px",
    },
  },
}));

const Root: React.FC<{ sessionId: string }> = ({ sessionId }) => {
  const classNames = useRootStyle();
  return (
    <div className={classNames.root}>
      <div className={"classNames.TitledPage"}>
        <ul className={classNames.dayAnchors}>
          <li>
            <a href="#day1">DAY.01</a>
          </li>
          <li>
            <a href="#day2">DAY.02</a>
          </li>
        </ul>
      </div>
      <div className={classNames.container}>
        <Contents />
      </div>
      <Dialog sessionId={sessionId} />
    </div>
  );
};
export default Root;

const useContenStyle = makeStyles(({ breakpoints, palette, spacing }) => ({
  container: {
    width: tableWidth,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: spacing(5),
    paddingRight: spacing(5),

    [breakpoints.down("xs")]: {
      width: "auto",
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  },
  TimetableTitle: {
    padding: "2.4rem 0",
    [breakpoints.down("xs")]: {
      padding: "1.2rem 0",
    },

    textAlign: "center",
    color: palette.primary.main,
  },
}));

const Contents: React.FC = () => {
  const classNames = useContenStyle();
  const { day1, day2 } = useModels();

  return (
    <React.Fragment>
      <Box className={classNames.container}>
        <h2 className={classNames.TimetableTitle} id="day1" title="2020/02/20">
          DAY.01 (Feb&nbsp;20th,&nbsp;2020)
        </h2>
        <Timetable
          data={new TimetableData(day1.sessions, day1.rooms)}
          columns={7}
        />
      </Box>
      <Box className={classNames.container}>
        <h2 className={classNames.TimetableTitle} id="day2" title="2020/02/21">
          DAY.02 (Feb&nbsp;21th,&nbsp;2020)
        </h2>
        <Timetable
          data={new TimetableData(day2.sessions, day2.rooms)}
          columns={7}
        />
      </Box>
    </React.Fragment>
  );
};

// ----------------------------------------------------------------
//     レイアウト/ダイアログ描画
// ----------------------------------------------------------------

/**
 * タイムテーブルのセッションをクリックしたのかどうかを管理します。
 * この変数はセッションをクリックしたタイミングで true になり、
 * その後セッションのダイアログが閉じたタイミングで false になります。
 *
 * グローバル変数にしているのは、history の変化を超えて管理できるスコープが他に思いつかなかったためです。
 * state で管理できるのであればそちらの方が望ましいと思っています。
 */
let sessionClicked = false;

const Dialog: React.FC<{ sessionId: string }> = ({ sessionId }) => {
  const lang = useLang();
  const onExit = useCallback(() => {
    // const { history, location } = this.props;
    // タイムテーブルを開きますが、ダイアログの開き方によって少しだけ動作が変わります。
    //
    // - タイムテーブルからダイアログが開かれた場合 … ブラウザの履歴を戻ります。
    // - その他の場合(URL直で開かれた場合など) … 通常の遷移をします。
    if (sessionClicked) {
      window.history.back();
    } else {
      navigate(resolve(lang, "/timetable"));
    }
  }, [lang]);
  const { sessions } = useModels();

  if (!sessionId) {
    sessionClicked = false;
    return <DetailDialog sessionizeSession={undefined} />;
  }

  const dialogSession =
    (sessionId && sessions.find((s) => s.id === sessionId)) || undefined;

  if (sessionClicked) {
    // React Static の autoScrollTop を無効化するためのハック
    // これが無いと戻った時に画面のトップにまでスクロールしてしまいます。
    //
    // この処理はタイムテーブルのセッションをクリックした時だけ有効になります。
    // URLから直接開いた時には有効にはなりません。
    // また、本番の時だけ __noScrollTo の更新タイミングが異なるため、
    // 開発の時も両方とも動作させるために、setTimeoutを挟んでいます。
    //
    // 参考: https://github.com/nozzle/react-static/blob/v5.9.12/src/client/components/RouterScroller.js#L15
    // setTimeout(() => {
    //   if (window.__noScrollTo === false) {
    //     window.__noScrollTo = true;
    //   } else if (window.__noScrollTo !== true) {
    // React Static 以外を使っている場合などはこのハックは無効なので、
    // とりあえず警告だけ出しておきます。
    console.warn(
      "timetable: window.__noScrollTo is undefined or the value is unexpected."
    );
    //   }
    // }, 200);
  }

  return <DetailDialog sessionizeSession={dialogSession} onExit={onExit} />;
};

// ----------------------------------------------------------------
//     レイアウト/タイムテーブル描画
// ----------------------------------------------------------------

const Timetable: React.FC<{ data: TimetableData }> = ({ data }) => {
  const classNames = useTableStyle();
  return (
    <table className={classNames.table}>
      <thead>
        <tr>
          <th />
          {data.rooms.names.map((n) => (
            <th key={n}>{n.toUpperCase()}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.rows.map(({ time, sessions }) => (
          <tr className={classNames.tr} key={time}>
            <th>{time}</th>
            {sessions.map((c, i) => (
              <RenderSessions session={c} key={i} />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

/**
 * セッション情報を表示します。
 *
 * @param {Object} session セッション情報、もしくはセッションがない場合は { key: … }
 * @param {Object} [session.sessionizeSession] Sessionize 形式のセッション情報
 * @param {number} [session.span] セルの高さ
 * @param {number} [session.key] セッションがない場合にタグの key 属性を渡します
 */
const RenderSessions: React.FC<{ session: TimetableCell }> = ({ session }) => {
  const { categories, rooms, speakers } = useModels();
  const classNames = useTableStyle();

  if (typeof session === "object") {
    const { sessionizeSession, startsAt, endsAt, span, sessionType } =
      session || {};
    const category = categories.of(sessionizeSession.categoryId);
    const room = rooms.name(sessionizeSession.room);
    const sps = sessionizeSession.speakerIds.map((s) => speakers.of(s).name);
    const sublang =
      sessionizeSession.language && sessionizeSession.interpretationTarget
        ? otherLang(sessionizeSession.language)
        : undefined;
    const title = sessionizeSession.title;
    const { id, message } = sessionizeSession;

    const duration = toDuration(startsAt, endsAt);
    switch (sessionType) {
      case "welcome_talk":
        return (
          <td className={classNames.td} key={id}>
            <SessionCard
              sessionLang="ja"
              sessionSubLang="en"
              title={title}
              duration={duration}
              room={room}
            />
          </td>
        );
      case "after_party":
        return (
          <td className={classNames.td} key={id}>
            <SessionCard
              title={`🎉 ${title} 🎉`}
              duration={duration}
              room="Exhibition"
            />
          </td>
        );
      case "lunch":
        return (
          <td className={classNames.break} key={id} colSpan={rooms.length}>
            <Headline color="textSecondary">
              🍱 {title.toUpperCase()} 🍱
              <br />
              {duration}min
            </Headline>
          </td>
        );
      case "reserved":
        return (
          <td className={classNames.td} key={id} rowSpan={span}>
            <SessionCard title={title} duration={duration} room={room} />
          </td>
        );
      default:
        return (
          <td className={classNames.td} key={id} rowSpan={span}>
            <SessionCard
              sessionLang={sessionizeSession.language}
              sessionSubLang={sublang}
              duration={duration}
              room={room}
              title={title}
              speaker={sps.join(", ")}
              topic={category.name}
              link={`/timetable/${id}`}
              message={message}
              onClick={() => {
                sessionClicked = true;
              }}
            />
          </td>
        );
    }
  }

  return <td className={classNames.tdNoneIfMobile} />;
};

const toDuration = (startsAt: string, endsAt: string): number => {
  return (
    (new Date(endsAt).getTime() - new Date(startsAt).getTime()) / (60 * 1000)
  );
};
