import React from "react";
import {
  withMobileDialog,
  IconButton,
  Hidden,
  Box,
  Typography,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MaterialButton from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Linkify from "react-linkify";
import { CategoryLabel, DialogLang } from "./Labels";
import { otherLang, useModels } from "./Models";
import { Session } from "helpers/timetable";
import { makeText } from "helpers/i18n";
import { ExternalLink } from "helpers/Link";
import { Close, InsertDriveFile, Link, PlayArrow } from "@material-ui/icons";
import { Headline, Body } from "helpers/text";

// ----------------------------------------------------------------
//     ローカライズ
// ----------------------------------------------------------------

const useText = makeText({
  en: {
    video: "Watch Video",
    slide: "View Slide",
  },
  ja: {
    video: "動画を見る",
    slide: "スライドを見る",
  },
});

// ----------------------------------------------------------------
//     スタイル
// ----------------------------------------------------------------

const useStyles = makeStyles(
  ({ typography, breakpoints, palette, spacing }) => ({
    root: {
      overflowY: "scroll",
    },

    contents: {
      padding: spacing(4),

      [breakpoints.down("xs")]: {
        padding: `0 ${spacing(2)}px ${spacing(2)}px`,
      },
    },

    paragraph: {
      marginTop: spacing(1),
      color: palette.secondary.main,
    },

    body: {
      marginTop: spacing(4),
      whiteSpace: "pre-wrap",
      fontSize: typography.body1.fontSize,

      "& a": {
        color: palette.primary.main,
      },
    },
    message: {
      color: palette.text.primary,
      whiteSpace: "pre-wrap",
    },

    externalLinkText: {
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: spacing(2),
    },

    speakerTop: {
      display: "flex",
    },

    speakerImagebox: {
      flex: "0 0 128px",
      height: "128px",
      borderRadius: "50%",
      background: palette.background.default,

      [breakpoints.down("xs")]: {
        flex: "0 0 96px",
        height: "96px",
      },

      "& img": {
        borderRadius: "50%",
      },
    },
  })
);

const DocLinkStyle = withStyles(({ palette, typography }) => ({
  root: {
    marginRight: 16,
    height: 40,
    minWidth: 160,
    borderRadius: 20,
    paddingLeft: 32,
    paddingRight: 32,
    color: palette.text.primary,

    background: palette.background.paper,
    "&:hover": {
      background: palette.background.default,
    },
  },

  label: {
    fontSize: typography.body1.fontSize,
  },
}))(MaterialButton);

const DocLinkComponent: React.FC<{ external: boolean }> = ({
  external,
  children,
  ...props
}) => {
  const attrs = external
    ? { rel: "noreferrer noopener", target: "_blank", ...props }
    : props;

  return <a {...attrs}>{children}</a>;
};

export const DocLink: React.FC<{ href: string; external: boolean }> = ({
  href,
  external,
  children,
}) => {
  return (
    <DocLinkStyle
      variant="contained"
      component={DocLinkComponent}
      external={external}
      href={href}
    >
      {children}
    </DocLinkStyle>
  );
};

const MobileDialog = withMobileDialog()(Dialog);

// ----------------------------------------------------------------
//     レイアウト
// ----------------------------------------------------------------

type DetailDialogProps = {
  sessionizeSession: Session | undefined;
  onExit?: () => void;
};

export const DetailDialog: React.FC<DetailDialogProps> = ({
  sessionizeSession,
  onExit,
}) => {
  const text = useText();
  const classNames = useStyles();
  const { categories, rooms, speakers } = useModels();

  if (!sessionizeSession) return <Dialog open={false}>&nbsp;</Dialog>;

  const lang = sessionizeSession.language;
  const category = categories.of(sessionizeSession.categoryId);
  const room = rooms.name(sessionizeSession.room);
  const time = timeToLongTextFormat(
    sessionizeSession.startsAt,
    sessionizeSession.endsAt
  );
  const speakersDetail = sessionizeSession.speakerIds.map((s) =>
    speakers.of(s)
  );
  const msg = sessionizeSession.message;
  const title = sessionizeSession.title;
  const videoUrl = undefined; // sessionizeSession.videoUrl;
  const slideUrl = undefined; // sessionizeSession.slideUrl;
  const sublang =
    lang && sessionizeSession.interpretationTarget && otherLang(lang);

  return (
    <MobileDialog open maxWidth="md" onBackdropClick={onExit}>
      <div className={classNames.root}>
        <Hidden smUp>
          <Box mx={1} mt={1}>
            <IconButton aria-label="Close" onClick={onExit}>
              <Close />
            </IconButton>
          </Box>
        </Hidden>
        <div className={classNames.contents}>
          <Typography variant="h3" style={{ lineHeight: 1.4 }}>
            {title}
          </Typography>
          <div>
            {lang && <DialogLang lang={lang} />}
            {
              " " /* react は二つの要素の間スペースが消去されるらしい…回避策としてスペースをあえて付与 */
            }
            {sublang && <DialogLang lang={sublang} interpretation />}
            {
              " " /* react は二つの要素の間スペースが消去されるらしい…回避策としてスペースをあえて付与 */
            }
            <Headline color="textSecondary" component={"span"}>
              {`${room} － ${time}`}
            </Headline>
          </div>
          <Box mt={2}>
            {category.name && <CategoryLabel>{category.name}</CategoryLabel>}
          </Box>
          {(() => {
            if (videoUrl || slideUrl) {
              return (
                <Box mt={2}>
                  {videoUrl && (
                    <DocLink href={videoUrl} external>
                      <PlayArrow />
                      &nbsp;&nbsp;&nbsp;
                      {text.video}
                    </DocLink>
                  )}
                  {slideUrl && (
                    <DocLink href={slideUrl} external>
                      <InsertDriveFile />
                      &nbsp;&nbsp;&nbsp;
                      {text.slide}
                    </DocLink>
                  )}
                </Box>
              );
            }
          })()}
          {msg && (
            <Box mt={2} className={classNames.message}>
              {msg}
            </Box>
          )}
          <div className={classNames.body}>
            <AutoLink text={sessionizeSession.description} />
          </div>

          {speakersDetail.map((s, i) => (
            <Box mt={5} style={{ display: "flex" }} key={i}>
              <div className={classNames.speakerImagebox}>
                {s.profilePicture && (
                  <img
                    style={{ width: "100%" }}
                    src={s.profilePicture}
                    alt={s.name}
                  />
                )}
              </div>
              <div className={classNames.contents}>
                <Headline>{s.name}</Headline>
                <Body style={{ fontStyle: "italic" }}>{s.tagLine}</Body>
                <div className={classNames.paragraph}>
                  {s.links.length ? <Link /> : []}
                  {s.links.map((l) => (
                    <a
                      className={classNames.externalLinkText}
                      key={l.url}
                      rel="noreferrer noopener"
                      target="_blank"
                      href={l.url}
                    >
                      {l.title}
                    </a>
                  ))}
                </div>
                <div className={classNames.body}>
                  <AutoLink text={s.bio} />
                </div>
              </div>
            </Box>
          ))}
        </div>
      </div>
    </MobileDialog>
  );
};

const AutoLink: React.FC<{ text: string }> = ({ text }) => (
  <Linkify
    componentDecorator={(href, text, key) => (
      <ExternalLink key={key} href={href}>
        {text}
      </ExternalLink>
    )}
  >
    {text}
  </Linkify>
);

const timeToLongTextFormat = (start: string, end: string): string => {
  const s = /(\d+)-(\d+)-(\d+)T(\d+):(\d+)/.exec(start);
  const e = /(\d+)-(\d+)-(\d+)T(\d+):(\d+)/.exec(end);
  if (s && e) {
    return `${s[1]}/${s[2]}/${s[3]} ${s[4]}:${s[5]}-${e[4]}:${e[5]}`;
  } else {
    return `${start} - ${end}`;
  }
};
