import { LangType } from "helpers/i18n";
import {
  Room,
  Session,
  Speakers,
  Categories,
  useTimetable,
} from "helpers/timetable";

// ----------------------------------------------------------------
//     各種表示データ/低レベル情報(部屋、スピーカー、カテゴリ)
// ----------------------------------------------------------------

export const ROOM_ID_HALL = 3869; // ホール二つ

/**
 * 使用する部屋の集合を管理します。
 *
 * ## データ形式について
 *
 * `this.values` には sessionize で提供された形式でデータが入っています。
 * このクラスではこの形式を隠蔽し、各メソッドは適宜加工された表現を返します。
 */
class Rooms {
  private readonly values: Room[];
  constructor(sessionizeRooms: Room[]) {
    this.values = sessionizeRooms.sort((r1, r2) => r1.sort - r2.sort);
  }

  get length(): number {
    return this.values.length;
  }

  get names(): string[] {
    // Sessionize のデータは Room 1 A+B のような名前になっているので、A+B を除去
    // 命名ルールが変わるとこちら側の変更も必須です
    return this.values.map((r) => r.name.replace(/(Room \d+).*/, "$1"));
  }

  name(id: number): string {
    return this.names[this.indexOf(id)];
  }

  room(id: number): Room | undefined {
    return this.values.find((r) => r.id === id);
  }

  indexOf(id: number): number {
    return this.values.findIndex((r) => r.id === id);
  }
}

/**
 * スピーカー情報の集合を管理します。
 */
// class Speakers {
//   constructor (sessionizeSpeakers) {
//     const values = {}
//     sessionizeSpeakers.forEach(s => {
//       values[s.id] = {
//         name: s.fullName,
//         tagLine: s.tagLine,
//         bio: s.bio,
//         profilePicture: s.profilePicture,
//         links: s.links
//       }
//     })

//     this.values = values
//   }

//   of (id) {
//     return this.values[id]
//   }
// }

/**
 * 質問の集合を管理します。
 */
// class Questions {
//   constructor (sessionizeQuestions, lang) {
//     this.sessionizeQuestions = sessionizeQuestions
//     this.lang = lang
//   }

//   name(id) {
//     const found = this.sessionizeQuestions.find(q => q.id === id)
//     return found && found.translatedQuestion[this.lang]
//   }
// }

/**
 * カテゴリ情報の集合を管理します。
 *
 * ## データ形式について
 *
 * sessionize はカテゴリの種類(Language など)と各カテゴリ(Englishなど)
 * の入れ子構造でデータを渡します。
 *
 * 一方、このクラスは以下のようにタイムライン表示に最適化した形に加工したカテゴリ情報を提供します
 * (`of` メソッド参照)。
 *
 * ```
 * {
 *   lang: 'ja',
 *   duration: '50',
 *   topic: '…',
 *   level: 'niche'
 * }
 * ```
 *
 * 内部表現は { category: '(カテゴリの種類)', value: '各カテゴリの値' } の配列となっています。
 */
// class Categories {
// constructor (sessionizeCategories, lang) {
// const items = {}
// sessionizeCategories.forEach(c => {
// c.items.forEach(({ id, name }) => {
// switch (c.title) {
// case 'Language': {
// const langId = id === 13201 ? 'en' : id === 13202 ? 'ja' : 'mixed'
// items[id] = { category: 'lang', value: langId }
// break
// }
// case 'Session format': {
// const minutes = name.replace(/minutes$/, '')
// items[id] = { category: 'duration', value: minutes }
// break
// }
// case 'カテゴリ / Category': {
// items[id] = { category: 'topic', value: this.parseJaAndEn(name, lang) }
// break
// }
// default: throw Error()
// }
// })
// })
// this.items = items
// }
//
// /**
//  * Sessionize データ形式(`(日本語) / (英語)`) から現在の言語のテキストを抽出します。
//  */
// parseJaAndEn (jaAndEn, lang) {
// const match = /(.+?) \((.+)\)/.exec(jaAndEn)
// if (match) {
// return lang === 'ja' ? match[1] : match[2]
// }
// return jaAndEn
// }
//
// of (categoryIds) {
// const res = {}
// categoryIds.forEach(i => {
// const { category, value } = this.items[i]
// res[category] = value
// })
// return res
// }
// }

export const otherLang = (lang: LangType): LangType =>
  lang === "ja" ? "en" : "ja";

// ----------------------------------------------------------------
//     各種表示データ/時間の枠の管理
// ----------------------------------------------------------------

/**
 * 時間情報の集合を管理します。
 *
 * ## データ形式について
 *
 * `this.values` には `2018-02-08T10:00:00` のような形式でデータが入っています。
 * このクラスではこの形式を隠蔽し、各メソッドは適宜加工された表現を返します。
 */
class TimeBlocks {
  public readonly values: string[];

  constructor(times: string[]) {
    const timeSet: { [time: string]: boolean } = {};
    times.forEach((s) => {
      timeSet[s] = true;
    });

    if (timeSet["2019-02-07T18:00:00"]) {
      // 1日目は最後のセッションが17:20に終わるパターンと17:40に終わるパターンがあります
      // 何もしないとこの二つが区別されないタイムテーブルができます。
      // その対処として、17:20に終わるブロックを強制的に追加しています。
      timeSet["2019-02-07T17:20:00"] = true;
    }

    this.values = Object.keys(timeSet).sort();
  }

  indexOf(time: string): number {
    return this.values.findIndex((t) => t === time);
  }

  indexAfter(time: string): number {
    const res = this.values.findIndex((t) => t >= time);
    return res === -1 ? this.values.length : res;
  }
}

// ----------------------------------------------------------------
//     各種表示データ/特別セッション
// ----------------------------------------------------------------

//
// ### セッション情報の形式
//
// {
//   startsAt: '(日付形式)',
//   endsAt: '(日付形式)',
//   room: { // 下の属性の打ちいずれか一つ
//     roomId: '(Sessionize で提供している部屋の ID、部屋がない場合は undefined)',
//     special: 'hallAB'もしくは'all',
//   },
//   sessionizeSession: 'Sessionize から受け取った講演情報。特別セッションの場合は undefined'
// }
//

interface SessionInfo {
  sessionizeSession: Session;
  room: { roomId: number };
  startsAt: string;
  endsAt: string;
  sessionType:
    | "after_party"
    | "welcome_talk"
    | "lunch"
    | "reserved"
    | undefined;
}

/**
 * Sessionize 形式の講演・ハンズオン情報を、本モジュールで使う形式でラップします。
 */
function toSessionInfo(sessionizeSession: Session): SessionInfo {
  // const { sessionType } = sessionizeSession;
  // const room =
  //   sessionType === "lunch"
  //     ? { special: "all" }
  //     : sessionType === "after_party"
  //     ? { special: "hallAB" }
  //     : { roomId: sessionizeSession.room };
  const room = { roomId: sessionizeSession.room };
  const { title } = sessionizeSession;

  return {
    startsAt: sessionizeSession.startsAt,
    endsAt: sessionizeSession.endsAt,
    room,
    sessionizeSession,
    sessionType:
      title === "Lunch"
        ? "lunch"
        : title === "Party"
        ? "after_party"
        : title === "Welcome Talk"
        ? "welcome_talk"
        : undefined,
  };
}

// ----------------------------------------------------------------
//     各種表示データ/
// ----------------------------------------------------------------

interface Models {
  rooms: Rooms;
  categories: Categories;
  speakers: Speakers;
  sessions: Session[];
  day1: DayInfo;
  day2: DayInfo;
}

export const useModels = (): Models => {
  const sessionizeAll = useTimetable();
  return {
    get rooms() {
      return new Rooms(sessionizeAll.rooms);
    },
    get categories() {
      // return new Categories(sessionizeAll.categories, ln);
      return sessionizeAll.categories;
    },
    get speakers() {
      // return new Speakers(sessionizeAll.speakers);
      return sessionizeAll.speakers;
    },
    // get questions() {
    //   return new Questions(sessionizeAll.questions, ln);
    // },
    get sessions() {
      return sessionizeAll.sessions;
    },
    get day1(): DayInfo {
      return {
        rooms: new Rooms(sessionizeAll.rooms),
        sessions: [
          ...sessionizeAll.sessions
            .filter((s) => s.startsAt.match(/^2020-02-20T.*/))
            .map(toSessionInfo),
        ],
      };
    },
    get day2(): DayInfo {
      return {
        rooms: new Rooms(
          sessionizeAll.rooms.filter((r) => !r.name.match(/^Hall/))
        ),
        sessions: [
          ...sessionizeAll.sessions
            .filter((s) => s.startsAt.match(/^2020-02-21T.*/))
            .map(toSessionInfo),
        ],
      };
    },
  };
};

interface DayInfo {
  rooms: Rooms;
  sessions: SessionInfo[];
}

// ----------------------------------------------------------------
//     各種表示データ/タイムテーブル用データ
// ----------------------------------------------------------------

/**
 * タイムテーブル表示で、特に要素を割り当てる必要が無いことを表します。
 */
const ALREADY_FILLED = "ALREADY_FILLED";
export type TimetableCell = (SessionInfo & { span: number }) | "ALREADY_FILLED";

/**
 * タイムテーブル表示に最適化した形でグループ化されたセッション情報です。
 */
export class TimetableData {
  private readonly timeBlocks: TimeBlocks;
  private readonly cells: TimetableCell[][];
  constructor(sessions: SessionInfo[], public readonly rooms: Rooms) {
    const timeBlocks = new TimeBlocks(sessions.map((s) => s.startsAt));
    const res: TimetableCell[][] = timeBlocks.values.map(
      () => new Array(rooms.length)
    );
    sessions.forEach((session) => {
      // const { room } = session;
      const start = timeBlocks.indexOf(session.startsAt);
      const span = timeBlocks.indexAfter(session.endsAt) - start;

      // res[rowのindex][columのindex]に部屋情報を埋めていきます。
      // テーブル生成時に<td>を埋める必要がない場合はALREADY_FILLEDを指定します。
      if (session.sessionType === "lunch") {
        res[start][0] = { ...session, span };
        for (let i = 1; i < rooms.length; ++i) {
          res[start][i] = ALREADY_FILLED;
        }
      } else {
        // 特定の部屋に流れるもの
        const roomIndex = rooms.indexOf(session.room.roomId);
        res[start][roomIndex] = { ...session, span };
        for (let i = 1; i < span; ++i) {
          res[start + i][roomIndex] = ALREADY_FILLED;
        }
      }
    });

    this.rooms = rooms;
    this.timeBlocks = timeBlocks;
    this.cells = res;
  }

  get rows(): {
    time: string;
    blockClass: { length: number; priority: string };
    sessions: TimetableCell[];
  }[] {
    return this.timeBlocks.values.map((t, i) => {
      const b = new TimeBlock(t);
      const r = this.cells[i];
      const sessions = this.rooms.names
        .map((_, i) => r[i])
        .filter((c) => c !== ALREADY_FILLED);

      return { time: b.time, blockClass: b.blockClass, sessions };
    });
  }
}

class TimeBlock {
  public readonly time: string;
  constructor(private readonly value: string) {
    this.time = value.replace(/^.*T(\d\d:\d\d):.*/, "$1");
  }

  get blockClass(): { length: number; priority: string } {
    switch (this.value) {
      case "2019-02-07T10:00:00":
        return { length: 0.5, priority: "major" };
      case "2019-02-07T15:40:00":
        return { length: 0.25, priority: "major" };
      case "2019-02-07T16:00:00":
        return { length: 0.75, priority: "minor" };
      case "2019-02-07T16:30:00":
        return { length: 0.25, priority: "major" };
      case "2019-02-07T16:40:00":
        return { length: 0.25, priority: "minor" };
      case "2019-02-07T16:50:00":
        return { length: 0.5, priority: "minor" };
      case "2019-02-07T17:10:00":
        return { length: 0.25, priority: "minor" };
      case "2019-02-07T17:20:00":
        return { length: 0.5, priority: "minor" };
      case "2019-02-08T14:50:00":
        return { length: 0.25, priority: "major" };
      case "2019-02-08T15:00:00":
        return { length: 0.75, priority: "minor" };
      default:
        return { length: 1, priority: "major" };
    }
  }
}
