import React from "react";
import { LangLabel, CategoryLabel } from "./Labels";
import { makeStyles, Paper, Box } from "@material-ui/core";
import Link from "helpers/Link";
import { Subheadline, Body, Caption } from "helpers/text";

// ----------------------------------------------------------------
//     タイムテーブル/カード内
// ----------------------------------------------------------------

const useStyles = makeStyles(
  ({ palette, breakpoints, typography, spacing }) => ({
    frame: {
      // div
      height: "100%",
      overflow: "hidden",
      // boxSizing: "content-box",
      // lineHeight: "1.4",
      // background: "white",
      // boxShadow: "0px 2px 2px rgba(160, 160, 160, 0.2)",
      [breakpoints.down("xs")]: {
        // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
        // borderRadius: "2px",
        paddingBottom: spacing(1),
      },
      "&.timetable-lang": {
        borderTopLeftRadius: "8px",
      },
    },

    link: {
      // styled(Link)`
      display: "block",
      height: "100%",
    },

    topContent: {
      marginLeft: "8px",
      marginRight: "8px",
    },

    header: {
      marginLeft: 0,
      lineHeight: "20px",
      textAlign: "right",
      [breakpoints.down("xs")]: {
        marginLeft: "0px",
        textAlign: "left",
      },
    },

    duration: {
      color: palette.text.secondary,
      verticalAlign: "top",
      fontSize: typography.caption.fontSize,
      [breakpoints.down("xs")]: {
        // fontSize: ${props => props.theme.fonts.normal};
        textAlign: "left",
        paddingLeft: "8px",
      },
    },

    title: {
      // fontSize: ${props => props.theme.fonts.small};
      // fontWeight: bold;
      marginTop: spacing(1),
      // marginBottom: "0.25em",

      [breakpoints.down("xs")]: {
        // fontSize: ${props => props.theme.fonts.normal};
      },

      "& a": {
        color: "inherit",
      },
    },

    message: {
      // fontSize: ${props => props.theme.fonts.small};
      marginTop: "0.25em",
      marginBottom: "0.25em",
      // color: ${props => props.theme.colors.main};
    },
  })
);

// ----------------------------------------------------------------
//     レイアウト/タイムテーブルの項目
// ----------------------------------------------------------------

type SesscionCardProps = {
  title: string;
  speaker?: string;
  duration: number;
  room: string;
  sessionLang?: "en" | "ja";
  sessionSubLang?: "en" | "ja";
  topic?: string;
  link?: string;
  message?: string;
  onClick?: () => void;
};

/**
 * セッションをテーブルに描画します
 */
const SessionCardMain: React.FC<SesscionCardProps> = ({
  title,
  speaker,
  duration,
  room,
  sessionLang,
  sessionSubLang,
  topic,
  link,
  message,
  onClick,
}) => {
  const c = useStyles();
  const content = (
    <React.Fragment>
      <div className={`${c.header} ${c.topContent}`}>
        {sessionLang ? <LangLabel lang={sessionLang} /> : []}
        {sessionSubLang ? <LangLabel lang={sessionSubLang} /> : []}
        <span className={c.duration}>
          {room} / {duration}min
        </span>
      </div>
      <Subheadline className={`${c.title} ${c.topContent}`}>
        {title}
      </Subheadline>
      <Box mt={1}>
        <Body color="textSecondary" className={c.topContent}>
          {speaker}
        </Body>
      </Box>
      <Box mt={1} lineHeight={2} className={c.topContent}>
        {topic ? (
          <CategoryLabel>
            <Caption>{topic}</Caption>
          </CategoryLabel>
        ) : (
          []
        )}
      </Box>
      {(() => {
        if (!message) return <React.Fragment />;
        return <p className={`${c.message} ${c.topContent}`}>{message}</p>;
      })()}
    </React.Fragment>
  );

  return (
    <Paper className={c.frame}>
      {link ? (
        // xeslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <Link className={c.link} to={link}>
          <div style={{ height: "100%" }} onClick={onClick}>
            {content}
          </div>
        </Link> // TODO スクロール調整
      ) : (
        content
      )}
    </Paper>
  );
};

export default SessionCardMain;
