import React from "react";

import Main from "../helpers/pages/timetable/Main";
import { Layout, main } from "helpers/MainLayout";
import { makeText, useLang, LangType } from "helpers/i18n";

const useText = makeText({
  en: { title: "Timetable" },
  ja: { title: "タイムテーブル" },
});

const TimetableContent: React.FC<{
  sessionId: string;
  title: Record<LangType, string> | undefined;
  description: string | undefined;
}> = ({ sessionId, title, description }) => {
  const text = useText();
  const lang = useLang();
  const localTitle = title ? title[lang] : undefined;

  return (
    <Layout title={localTitle || text.title} description={description}>
      <Main sessionId={typeof sessionId === "string" ? sessionId : ""} />
    </Layout>
  );
};

const TimetableMain = main(TimetableContent);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TimetablePage: React.FC<{ pageContext: any }> = ({ pageContext }) => {
  const { sessionId, title, description } = pageContext;
  return (
    <TimetableMain
      sessionId={typeof sessionId === "string" ? sessionId : ""}
      title={title}
      description={description}
    />
  );
};

export default TimetablePage;
