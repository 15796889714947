import React from "react";
import { makeText } from "helpers/i18n";
import { makeStyles } from "@material-ui/core";
import { Body } from "helpers/text";

const useText = makeText({
  en: {
    interpretation: "(Simultaneous Interpretation)",
  },
  ja: {
    interpretation: "(同時通訳)",
  },
});

const useLangLabelStyle = makeStyles(({ palette, typography }) => ({
  root: {
    float: "left",
    padding: "0 4px",
    fontSize: typography.caption.fontSize,
    fontWeight: 500,
    color: "#fff",
  },
  ja: {
    background: palette.primary.main,
  },
  en: {
    background: "#48D597",
  },

  dialogLang: {
    padding: "2px 4px",
    color: "#fff",
  },
}));

type LangAttributes = { lang: "ja" | "en" };

export const LangLabel: React.FC<LangAttributes> = ({ lang, ...props }) => {
  const classNames = useLangLabelStyle();
  return (
    <span className={`${classNames.root} ${classNames[lang]}`} {...props}>
      {lang.toUpperCase()}
    </span>
  );
};

export const DialogLang: React.FC<
  LangAttributes & {
    interpretation?: boolean;
  }
> = ({ interpretation, lang, ...props }) => {
  const text = useText();
  const classNames = useLangLabelStyle();
  return (
    <Body
      component="span"
      className={`${classNames.dialogLang} ${classNames[lang]}`}
      {...props}
    >
      {lang.toUpperCase()}
      {interpretation && text.interpretation}
    </Body>
  );
};

const useCategoryStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    marginRight: "0.5ex",
    padding: "0.25ex 0.5ex",
    lineHeight: 1.2,
    borderRadius: "4px",
    background: palette.timetableBorder,

    [breakpoints.down("xs")]: {
      fontSize: "90%",
    },
  },
}));

export const CategoryLabel: React.FC = ({ children }) => {
  const classNames = useCategoryStyles();
  return <span className={classNames.root}>{children}</span>;
};
