import { makeStyles } from "@material-ui/core";

const minCellWidth = 145;
const maxColumns = 9;
export const tableWidth = minCellWidth * maxColumns + 100;
const cellWidth = (columns: number): number => (tableWidth - 100) / columns;

export const useTableStyle = makeStyles(
  ({ breakpoints, typography, palette }) => ({
    table: {
      borderCollapse: "collapse",
      [breakpoints.down("xs")]: {
        "& th, & td": {
          display: "block",
        },
      },

      "&>*>*>th": {
        color: palette.text.disabled,
        fontSize: typography.subtitle1.fontSize,
      },

      "&>thead>tr>th": {
        padding: "16px 0",
        textAlign: "center",

        [breakpoints.down("xs")]: {
          display: "none",
        },
      },

      "&>tbody>tr>th": {
        width: "68px",
        paddingTop: "8px",
        paddingRight: "16px",
        textAlign: "left",
        verticalAlign: "top !important",

        [breakpoints.down("xs")]: {
          // fontSize: ${props => props.theme.fonts.large};
          paddingTop: "24px",
          paddingBottom: "8px",
        },
      },

      "&>*>*>td": {
        width: cellWidth(7),
        [breakpoints.down("xs")]: {
          width: "auto",
        },
      },
    },

    td: {
      padding: "8px 4px",
      verticalAlign: "top",
      borderLeft: "1px solid transparent",

      [breakpoints.down("xs")]: {
        width: "100%",
        borderLeft: "none",
        padding: "4px 0px",
      },
    },

    tr: {
      height: 240,
      borderTop: `1px solid ${palette.timetableBorder}`,

      [breakpoints.down("xs")]: {
        height: "auto",
        borderTop: "none",
      },

      "&>td, &>th": {
        height: 240,
        [breakpoints.down("xs")]: {
          height: "auto",
        },
      },
    },

    break: {
      height: "150px",
      textAlign: "center",
      verticalAlign: "middle",

      [breakpoints.down("xs")]: {
        height: "auto",
      },
    },

    tdNoneIfMobile: {
      [breakpoints.down("xs")]: {
        display: "none !important",
      },
    },
  })
);
